html {
  scroll-behavior: smooth;
}
.section {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-0 {
  padding: 0;
}

.banner-fs .banner-wrap {
  padding-top: 40px;
  padding-bottom: 40px;
}
.header-main {
  padding: 4px 0 !important;
}
#header:after{

}

.td1 #p.p1 {
  margin-right: 0px;
  margin-left: 0px;
  font-stretch: normal;
  line-height: normal;
}

.td1 {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(154, 154, 154);
  padding: 1px 5px;
}

#economics .section-head,
#roadmap .section-head {
  padding-bottom: 40px !important;
}

.section-head .title-s4 {
  padding-bottom: 40px !important;
}
.solanaimg {
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin: 0 5px;
}

.logo img {
  height: 80px;
}

.counter {
  margin: -15px 0 30px;
  font-size: 16px;
  font-style: italic;
}

.has-fixed .header-main {
  top: 0;
}
.has-fixed.is-shrink .header-main {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.has-mask .banner-mask-fix {
  padding-bottom: 0px !important;
}

#ant-modal {
  z-index: 100000;
  position: absolute;
}

#root {
  position: relative;
}

#root .btn.btn-md {
  font-size: 18px !important;
  padding: 15px 45px !important;
  white-space: break-spaces;
}

#root .btn.btn-md[disabled] {
  font-size: 12px !important;
  max-width: 80%;
  overflow: hidden;
}

.ant-layout {
  background: transparent;
}

.ant-layout-header {
  background: transparent !important;
}

#root main {
  background: transparent !important;
  padding: 0 !important;
}

.App-Bar {
  height: auto !important;
  padding-top: 0 !important;
  position: absolute !important;
  bottom: 0 !important;
  height: 40px !important;
  padding: 0 !important;
  top: auto !important;
}

.App-Bar-right > .ant-btn.ant-btn-primary.ant-btn-lg {
  background: transparent !important;
  border: none !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.ant-btn > span {
  vertical-align: middle;
}

.App-Bar-right {
  margin: 0 auto;
}

#root main .ant-btn-primary {
  margin: 0 auto;
  display: block;
  white-space: break-spaces;
  height: auto;
}

.ant-select-dropdown {
  z-index: 100000 !important;
}

.ant-btn.ant-btn-text.ant-btn-circle.ant-btn-lg.ant-btn-icon-only {
  display: none !important;
}

.eapps-social-share-buttons {
  position: fixed !important;
  z-index: 100 !important;
}

.circle {
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 100%;
  position: relative;
  background-color: #fff;
}

.circle:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circle-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

.score-text {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1em;
  line-height: 1em;
  font-size: 32px;
}

.newsletter-box {
  padding: 0 0;
  width: 100%;
}

.newsletter-box input {
  width: 67%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: #1a1a1a;
  border: 0px solid #fff;
  color: white !important;
  font-size: 16px;
  font-weight: bold;
  padding: 15px;
}


.newsletter-box button {
  margin-left: 1%;
  width: 32%;
  height: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #1a1a1a;
  color: white;
  font-size: 16px;
  font-weight: bold;
  content: "" !important;
}
::placeholder {
  color: white !important;
  opacity: 1;
}

.placeholder-color{
  background-color: #1a1a1a;
}

.newsletter-box button {

  position: relative;
}


.discord-box {
  padding: 5px 0px 35px 0px;
  width: 60%;
}
@media (max-width: 799px) {
  .discord-box {
    width: 100%;
  }
}

.discord-box button {
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #2afd6c 0%, #2afd6c 100%) !important;
  color: #1a1a1a !important;
  font-size: 16px;
  font-weight: bold;
}
.discord-box .spaceship-active{
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #2afd6c 0%, #2afd6c 100%) !important;
  color: #1a1a1a !important;
  font-size: 16px;
  font-weight: bold;
}

.discord{
  /*width: 100%;*/
  /*height: 60px;*/
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #2afd6c 0%, #2afd6c 100%);
}

.discord .MuiButton-label{
  color: #1a1a1a !important;
  font-size: 12px;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
}


.border-theme {

  border: #2afd6c 1px solid;
}


.margin-minus-17 {
  margin-left: -17px;
}

.zero-margin-bottom {
  margin-bottom:  0px !important;
  padding-bottom: 0px !important;
}

.to-capitalize {
  text-transform: capitalize;
}

.color-theme {
  color: #2afd6c !important;
}

span.color-theme-bold {
  color: #2afd6c !important;
  padding: 0 !important;
  font-weight: 900;
}

span.color-theme-dark-bold {
  color: #1a1a1a;
  padding: 0 !important;
  font-weight: 900;
}

.no-padding {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.zero-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 20px;
}
@media (max-width: 799px) {
  .no-padding {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .zero-padding {
    padding-left: 3px !important;
    padding-right: 0px !important;
    margin-bottom: 20px;
  }
  #team h5{
    padding-top: 5px;
  }
  .team{
    margin-bottom: 10px !important;
  }
  .team-photo img {
    width: 90% !important;
  }
  .zero-zero {
    /*padding-top: 15px !important;*/
    padding-top: 30px !important;
  }
}

.zero-zero {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-margin {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.zero-margin {
  margin: 0px !important;
}
.no-line-height{
line-height: 1 !important;

}

.bold-text {
  font-weight: bold !important;
}

.small-text {
  font-size: 12px !important;
}

.lines-border {
  border: #1a1a1a 1px solid;
}

.lines-border-bottom {
  border-bottom: #1a1a1a 1px solid;
}
.min-height-titles {
  min-height: 30px !important;
}

.utilities-img {
  width: 100% !important;
}

.bg-theme-martian-background {
  background-image: url('https://prod-martianarmy.imgix.net/v2/backgrounds/BG-full.png?auto=format&fit=crop') !important;
}

.bg-theme-martian-about-background::before {
  content: '';
  background-image: url('https://prod-martianarmy.imgix.net/v2/backgrounds/BG2.png?auto=format&fit=crop') !important;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

}

.bg-theme-martian-roadmap-background::before {
  content: '';
  background-image: url('https://prod-martianarmy.imgix.net/v2/backgrounds/Bubble-Background.svg?auto=format&fit=crop') !important;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.08;
}

.bg-theme-martian-team-background::before {
  content: '';
  background-image: url('https://prod-martianarmy.imgix.net/v2/backgrounds/BG2.png?auto=format&fit=crop') !important;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}

.bg-theme-martian-faq-background::before {
  content: '';
  background-image: url('https://prod-martianarmy.imgix.net/backgrounds/Bubble-Background.svg?auto=format&fit=crop') !important;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
}

.bg-theme-martian-footer-background::before {
  content: '';
  background-image: url('https://prod-martianarmy.imgix.net/backgrounds/Bubble-Background.svg?auto=format&fit=crop') !important;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}

ul li::before {
  content: '\2022';
  color: #2afd6c;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

ul.social li::before{
  list-style-type: none !important;
  content: none;
}

#faq-spaceship, #faq-mars {
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  min-width: 300px !important;
  background: linear-gradient(180deg, #2afd6c 0%, #2afd6c 100%);
  color: #1a1a1a !important;
  font-size: 22px !important;
  font-weight: bold !important;
}

.game-frame{
  width: 100vw;
  height: 100vh;
  border: unset;
  position: fixed;
  left:0;
}
.whitepaper-frame{
  width: 100vw;
  height: 96vh;
  border: unset;
  position: fixed;
  left:0;
  padding-top: 62px;
}

.spaceship-frame{
  width: 100%;
  height: 100vh;
  border: unset;
  position: relative;
  left:0;

}
.utility-text{
  font-size: 13px !important;
}

.text-color-white{
  color: white;
}

.text-color-green{
  color: #2afd6c !important;
}

.text-color-black{
  color: #1a1a1a;
}
.bg-color-green{
  background-color: #2afd6c;
}

.marquee {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 75px;
  overflow-x: hidden;
  font-size: 22px;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}

.stage-info{
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1150px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1350px;
  }
}

.no-top-margin{
  margin-top: 0px !important;
}

.ma-logo{
  background-image: url("https://prod-martianarmy.imgix.net/v2/Group 520.png?auto=format&fit=crop");
  background-repeat: no-repeat;
  background-position: right;
}

.left-margin-10{
  margin-left: 10px;
}

.left-margin-20{
  margin-left: 20px;
}

.margin-bottomminus-10{
  margin-bottom: -15px;
}

.spacemap-border{

  border-left: #1a1a1a 1px solid;
  border-bottom: #1a1a1a 1px solid;
}
.spacemap-border-2x {
  border-left: #1a1a1a 1px solid;
  border-bottom: #1a1a1a 1px solid;
}
.spacemap-border-right{
  border-right: #1a1a1a 1px solid;
}

@media (min-width: 800px) {
  .spacemap-border-2x {
    height: 216px;
    border-left: #1a1a1a 1px solid;
    border-bottom: #1a1a1a 1px solid;
  }
  .spacemap-border{
    height: 108px!important;
    border-left: #1a1a1a 1px solid;
    border-bottom: #1a1a1a 1px solid;
  }

}


@media (max-width: 800px) {
  #roadmapContainer div.bg-theme::before {
    content: '';
    background-image: url('https://prod-martianarmy.imgix.net/backgrounds/Bubble-Background.svg?auto=format&fit=crop') !important;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.08;
  }
  .cpn-head h1.title {
    font-size: 3.15rem !important;
    text-align: left;
  }
  .cpn-head h2.title {
    font-size: 2.15rem !important;
  }
}

.roadmapContainer div{
  padding: 0px !important;
  margin: 0px !important;
}
.section-roadmap{
  padding:0px !important;
}

.no-border-left{
  border-left:0px !important;
}

#roadmap p{
  font-size: 14px;
}

.img-50 img{
  height: 50px !important;
  width: 50px !important;
}
#roadmap img{
  width: 100%;
  height: 100%;
  object-fit: fill;
  overflow: hidden;
}


#FAQ-SECTION h4{
  font-size: 20px;
  margin-bottom: 2px;
  margin-top: 30px;
}


.fix-bottom{
  position: absolute;
  bottom: 0px;
}

.menu-border-bottom{

}


@media (max-width: 799px) {
  .display-mobile-none {
    display: none !important
  }
}

@media (min-width: 800px) {
  .display-mobile-none {
    display: flex !important
  }
  .display-desktop-none{
    display: none !important
  }
}

@media (max-width: 1025px){
  .header-container{
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .header-container .menu-item > a{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.header-navbar-s1 .menu > .menu-item > a {
  padding: 20px 6px !important;
  font-size: 14px !important;
}
@media (min-width: 1399px){
  .header-navbar-s1 .menu > .menu-item > a {
    padding: 30px 15px !important;
    font-size: 15px !important;
  }

}
@media (min-width: 1399px) {
  #mint-box:before {
    position: absolute;
    /*bottom: 30px;*/
    z-index: 99;
    border-radius: 10px;
    content: "";
    height: 140px;
    width: 140px;
    background-image: url("../images/v2/martian-banner-50-quick.gif?fit=crop");
    background-size: contain;
  }
}
.token-status {
  padding:0 !important ;
}
.MuiPaper-elevation1 {
  min-height: 140px;
  padding-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.wallet-connect-button-div{
  position: absolute;
  bottom: 0px;
  width: 80%;
}
.MuiButtonBase-root{

  color: #1a1a1a !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained{
  background: #2afd6c !important;
  /*position: absolute;*/
  /*bottom: 0px;*/
  /*width: 80%;*/
  /*left: 10px;*/
}

.section-economics .MuiButtonBase-root.MuiButton-root.MuiButton-contained.spaceship {
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 16px !important;
  color: white !important;
  background-color: #1a1a1a !important;
  border: white 1px solid !important;
}

.section-economics .MuiButtonBase-root.MuiButton-root.MuiButton-contained.spaceship-active {
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 16px !important;
  color: #1a1a1a !important;
  background: linear-gradient(180deg, #2afd6c 0%, #2afd6c 100%) !important;
}

.MuiDialogTitle-root, MuiDialogContent-root{
background-color: #1a1a1a !important;
  color: white !important;
}
.MuiDialog-paper .MuiTypography-h6, .MuiListItem-root .MuiButton-text{
  color: white !important;
}

.MuiBackdrop-root{
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.makeStyles-root-2 .MuiPaper-root{
  background-color: transparent !important;
}

.bg-red {
  background-color: red !important
}

.bg-yellow {
  background-color: yellow !important
}

.bg-pink {
  background-color: pink !important
}

html, body {
  overflow-x: hidden;
}


#FAQ-TITLE-FOOTER {
  line-height: 4rem;
}
.line-height-1{
  line-height: 1 !important;
}

.margin-top-8{
  padding-top: 8px !important;
}

.margin-top-10{
  padding-top: 10px !important;
}
.margin-top-99{
  padding-top: 99px !important;
}


@media (max-width: 1336px){
  #spacemap h5 {
    line-height: 1 !important;
    font-size: 1rem !important;
  }
  #spacemap h2 {
    line-height: 1 !important;
    font-size: 1.8rem !important;
  }
}

.fa-1-5x {
  font-size: 1.3em
}

.solana-text {
  font-size: 1.4em
}
.team .social a:hover {
  color: #2afd6c !important;
}

.minting-text{
  font-size: 1em !important;
}
.minting-text span{
  font-size: 1.2em !important;
}
@media (min-width: 1336px){
  .minting-text{
    font-size: 1.3em !important;
  }
  .minting-text span{
    font-size: 1.5em !important;
  }
}

section.section{
  padding-top: 60px !important;
}
#roadmap{
  padding-top: 0px !important;
}
#FAQ-SECTION{
  padding-bottom: 0px !important;
}

.token-image{
  width: 50%;
}

.utility-box-title{
  justify-content: space-between;
}
.utility-text{
  margin-left: 0;
}
.reload-div{
  width: 40px;
  margin-right: 10px;
}

.reload-div .fa-refresh{
  font-size: 2.7em !important;
}
.navbar-toggle.mint-true{
  display: none;
}
.reload-div.mint-true{
  display: inline-block;
}

.navbar-toggle.mint-false{
  display: inline-block;
}

.reload-div.mint-false{
  display: none;
}

.partners-ul{
  width: 100%;
}
.partners-ul li{
display: block !important;
}

.mobile-menu-active .menu-item{
  display: list-item !important;
}

.consulting-img{
  width: fit-content !important;
  height: fit-content !important;
}

